import React, { FunctionComponent, Fragment } from "react"
import { IndexPageConfig } from "../../../../../gatsby/config/create-pages/types"
import { IndexPageHeader, IndexPageKey } from "../../../../types/page"
import { Resource } from "../../../../types/resource"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { FeaturedResources } from "../../../../components/featured-resources/featured-resources"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { getFilteredIndexes } from "../../../search/search-helpers"

export interface PostPageProps {
  pageKey: IndexPageKey
  featuredLabel: string
  featuredList: Resource[]
  indexConfig: IndexPageConfig
  pageHeader: IndexPageHeader
  disableFilters?: boolean
}

export const PostPage: FunctionComponent<PostPageProps> = ({
  children,
  featuredLabel,
  featuredList,
  indexConfig,
  pageHeader,
  pageKey
}) => (
  <Fragment>
    <IndexHeader header={pageHeader}>{children}</IndexHeader>
    <FeaturedResources
      shouldUpdateList
      pageKey={pageKey}
      labelId={featuredLabel}
      list={featuredList}
      type="post"
    />
    <SearchResults
      filters={[]}
      config={indexConfig.searchConfig}
      variant="list"
      indexes={indexConfig.indexes ? getFilteredIndexes(["newest"]) : undefined}
    />
  </Fragment>
)
